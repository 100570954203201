@keyframes slide-up {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-down {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

.parallax {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

    &--slide {
        position: absolute;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;

        &.current {
            z-index: 2;
            &.slide-up {
                animation: slide-up 600ms;
            }
            &.slide-down {
                animation: slide-down 600ms;
            }
        }
    }
}