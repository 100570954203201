@import '../../styles/variables';

.terms-modal, .disclaimer-modal {

    &--main {
        position: absolute;
        width: 100%;
        max-width: 1000px;
        background: #eee;
        color: #2c2c2c;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        top: 0;
        bottom: 0;
    }

    &--header {
        position: relative;
        padding: 20px 50px 10px;
        display: flex;
        align-items: center;

        @media ($medium-screen) { 

        }
        @media ($small-screen) {
            padding: 10px 20px 10px;
        }
    }

    &--title {
        font-size: 32px;
        font-weight: 700;
        width: 100%;

        @media ($medium-screen) { 

        }
        @media ($small-screen) {
            font-size: 24px;
        }
    }

    &--close-button {
        font-size: 24px;
        opacity: 0.6;
        transition: all 0.3s;
        margin: 0;
        padding: 0;
        color: black;
        background: transparent;

        &:hover {
            opacity: 1.0;
        }

        >svg {
            transform: translateY(2px);
        }
    }

    &--body {
        position: relative;
        height: 100%;
        overflow: auto;
        padding: 0px 50px 30px;

        @media ($medium-screen) { 

        }
        @media ($small-screen) {
            padding: 00px 20px 30px;
        }

        h3 {
            font-size: 20px;
            font-weight: 600;
        }

        p {
            font-size: 16px;
            line-height: 24px;
        }
    }

}