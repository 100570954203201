@import '../styles/variables';

@keyframes slide-left {
    from {
        transform: translateX(10%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1.0;
    }
}

@keyframes pulse {
    0% {
        opacity: 0.4;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.4;
    }
}

.home-page {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

    &--header {
        position: relative;
        z-index: 10;
    }

    &--main {
        position: absolute;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
    }

    &--content {
        position: absolute;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;

        &--preview {
            position: absolute;
            padding: 0;
            width: 100%;
            top: 80px;
            bottom: 30px;
            padding: 30px 0px;
            overflow-y: auto;
            color: white;

            @media ($medium-screen) { 
                width: calc(100% - 40px);
                padding: 20px 20px 30px;
            }
            @media ($small-screen) {
                top: 60px;
                bottom: 0px;
                padding: 10px 20px 20px;
            }

            &--body {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                max-width: 1000px;
                min-height: 100%;
                margin: 0 auto;
            }
        }

        &--main {
            position: absolute;
            padding: 0;
            width: 100%;
            top: 80px;
            bottom: 30px;
            overflow-y: auto;
            color: white;

            @media ($medium-screen) { 
            }
            @media ($small-screen) {
                top: 60px;
                bottom: 0px;
            }
        }

        &--body {
            position: relative;
            width: 100%;
            max-width: 1000px;
            margin: 0 auto;
            padding: 30px 0px 50px;

            @media ($medium-screen) { 
                width: calc(100% - 40px);
                padding: 10px 20px 50px;
            }
            @media ($small-screen) {
            }

            >section {
                animation: slide-left 300ms;
                padding: 50px 0px 80px;

                @media ($medium-screen) { 
                    padding: 20px 0px 80px;
                }
                @media ($small-screen) {
                }
            }
        }

        &--back-button {
            font-size: 16px;
            font-weight: 600;
            margin: 0;
            padding: 0;
            height: 30px;
            background: transparent;
            color: white;
            text-decoration: underline;
            text-underline-offset: 2px;
            opacity: 0.7;

            &:hover {
                opacity: 1.0;
            }

            >svg {
                vertical-align: middle;
                transform: scale(1.5);
                margin-right: 10px;
            }
        }
    }

    &--video-background {
        >video {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    
    &--video-curtain {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #00000066;
    }

    >.footer {
        @media ($medium-screen) { 
        }
        @media ($small-screen) {
            display: none;
        }
    }
}

.landing-preview {
    position: relative;
    text-align: center;
    color: white;

    @media ($medium-screen) { 
    }
    @media ($small-screen) {
        margin-top: -20px;
    }

    &--slogan {
        font-weight: 700;
        font-size: 46px;
        line-height: 62px;

        @media ($medium-screen) { 
            font-size: 32px;
            line-height: 44px;
        }
        @media ($small-screen) {
        }
    }

    &--description {
        position: relative;
        margin: 0 auto;
        font-size: 19px;
        font-weight: 400;
        margin-top: 20px;
        max-width: 580px;
        line-height: 32px;
        text-align: center;

        @media ($medium-screen) { 
            font-size: 18px;
            line-height: 28px;
        }
        @media ($small-screen) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    &--buttons {
        position: relative;
        margin-top: 60px;

        @media ($medium-screen) { 
        }
        @media ($small-screen) {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
        }
    }

    &--button {
        position: relative;
        margin: 0px 20px;
        font-size: 16px;
        font-weight: 600;
        padding: 0px 20px;
        height: 44px;
        color: #ddd;
        transition: all 0.5s;

        @media ($medium-screen) { 
        }
        @media ($small-screen) {
            margin: 5px 0px;
            width: 240px;
            padding: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        &.market-making {
            background: #325b3f9e;

            &:hover {
                background: #325b3f;
                color: white;
            }
        }

        &.career {
            background: #2a1d34a1;

            &:hover {
                background: #2a1d34;
                color: white;
            }
        }
    }

    &--scroll-down-button {
        position: fixed;
        bottom: 50px;
        background: none;
        font-size: 24px;
        margin: 0;
        padding: 0;
        color: white;
        opacity: 0.6;
        animation: pulse 2s infinite;
        animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1.4);
        transition: all 0.3s;

        @media ($medium-screen) { 
        }
        @media ($small-screen) {
            bottom: 20px;
            font-size: 18px;
        }

        &:hover {
            opacity: 1.0;
        }
    }
}

.market-making-preview, .otc-preview, .career-preview, .contact-preview {
    position: relative;
    color: white;

    @media ($medium-screen) { 
    }
    @media ($small-screen) {
        margin-top: -20px;
    }

    &--title {
        font-weight: 700;
        font-size: 46px;

        @media ($medium-screen) { 
            font-size: 32px;
            line-height: 44px;
        }
        @media ($small-screen) {
            font-size: 32px;
            line-height: 44px;
        }

        >br {
            @media ($medium-screen) { 
            }
            @media ($small-screen) {
                display: none;
            }
        }
    }

    &--description {
        position: relative;
        font-size: 19px;
        font-weight: 400;
        margin-top: 20px;
        max-width: 650px;
        line-height: 32px;

        @media ($medium-screen) { 
            font-size: 18px;
            line-height: 28px;
        }
        @media ($small-screen) {
            font-size: 16px;
            line-height: 24px;
        }

        >br {
            @media ($medium-screen) { 
            }
            @media ($small-screen) {
                display: none;
            }
        }
    }

    &--read-more-button {
        margin-top: 50px;
        font-size: 16px;
        font-weight: 600;
        padding: 0px 20px;
        height: 44px;
        background: #ffffff99;
        color: black;
        transition: all 0.3s;

        &:hover {
            background: white;
        }
    }
}

.market-making-preview {
    &--read-more-button {
        color: #325b3f;
    }
}

.otc-preview {
    &--title {
        max-width: 800px;
    }
    &--read-more-button {
        color: #161e3e;
    }
}

.career-preview {
    text-align: right;
    &--title {
        max-width: 600px;
        text-align: right;
        margin-left: auto;
    }
    &--description {
        text-align: right;
        margin-left: auto;
    }
    &--read-more-button {
        color: #2a1d33;
    }
}

.contact-preview {
    text-align: center;

    &--description {
        text-align: center;
        max-width: unset;
        font-weight: 500;
    }

    &--read-more-button {
        color: #360809;
    }
}

.market-making-main, .otc-main {
    color: white;

    @media ($medium-screen) { 
        padding: 20px 0px;
    }
    @media ($small-screen) {
        padding: 10px 0px 20px;
    }

    &--title {
        font-weight: 700;
        font-size: 30px;

        @media ($medium-screen) { 
            font-size: 28px;
        }
        @media ($small-screen) {
            font-size: 24px;
        }
    }
    &--description {
        position: relative;
        font-size: 18px;
        font-weight: 400;
        margin-top: 20px;
        line-height: 32px;

        @media ($medium-screen) { 
        }
        @media ($small-screen) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    >section {
        .content-block {
            width: 50%;
            margin-top: 60px;

            @media ($medium-screen) { 
            }
            @media ($small-screen) {
                width: 100%;
                margin-top: 30px;
            }
        }
    }
}

.market-making-main {
    &--title {
        font-weight: 700;
        font-size: 38px;
        text-align: center;

        @media ($medium-screen) { 
            font-size: 32px;
            line-height: 44px;
        }
        @media ($small-screen) {
            font-size: 20px;
            line-height: 32px;
        }
    }
}

.content-block {
    display: inline-block;
    text-align: center;
    vertical-align: top;

    &--icon {
        font-size: 50px;
        color: #ddd;

        @media ($medium-screen) { 
        }
        @media ($small-screen) {
            font-size: 40px;
        }
    }

    &--title {
        font-weight: 700;
        font-size: 24px;

        @media ($medium-screen) { 
            font-size: 20px;
        }
        @media ($small-screen) {
        }
    }

    &--description {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        max-width: 400px;
        margin: 20px auto 0;
        text-align: center;

        @media ($medium-screen) { 
        }
        @media ($small-screen) {
            font-size: 14px;
            line-height: 20px;
        }
    }
}

.career-main {
    &--description {
        position: relative;
        font-size: 18px;
        font-weight: 500;
        line-height: 32px;

        @media ($medium-screen) { 
        }
        @media ($small-screen) {
            font-size: 16px;
            line-height: 28px;
        }
    }

    &--title {
        font-weight: 800;
        font-size: 32px;
        line-height: 40px;
        margin-top: 80px;

        @media ($medium-screen) { 
        }
        @media ($small-screen) {
            font-size: 28px;
            line-height: 36px;
            margin-top: 60px;
        }
    }

    &--roles {
        margin-top: 50px;

        @media ($medium-screen) { 
        }
        @media ($small-screen) {
            margin-top: 30px;
        }
    }

    &--role {
        margin-top: 50px;
        &--title {
            font-weight: 700;
            font-size: 24px;
            color: #e8ba47;

            @media ($medium-screen) { 
            }
            @media ($small-screen) {
                font-size: 20px;
            }
        }
        &--responsibility, &--capability {
            >label {
                display: block;
                margin-top: 20px;
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                color: #ccc;
            }
        }
    }

    li {
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        margin-top: 10px;

        @media ($medium-screen) { 
        }
        @media ($small-screen) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    &--benefit {
        &--title {
            font-weight: 800;
            font-size: 32px;
            line-height: 40px;
            margin-top: 80px;

            @media ($medium-screen) { 
            }
            @media ($small-screen) {
                font-size: 28px;
                line-height: 36px;
                margin-top: 60px;
            }
        }

        &--description {
            position: relative;
            font-size: 18px;
            font-weight: 500;
            line-height: 32px;
            margin-top: 20px;

            @media ($medium-screen) { 
            }
            @media ($small-screen) {
                font-size: 16px;
                line-height: 28px;
            }
        }
    }

    &--remarks {
        margin-top: 80px;
        >div {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;

            &:not(:first-child) {
                margin-top: 10px;
            }

            >span {
                color: #e8ba47;
                font-weight: 500;
                user-select: text;
            }
        }
    }
}