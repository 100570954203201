
@import '../../styles/variables';

@keyframes layout-slide-down {
    from {
        transform: translateY(-50%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1.0;
    }
}


.header {
    position: fixed;
    display: flex;
    align-items: center;
    z-index: 2;
    width: 100%;
    max-width: 1000px;
    height: 80px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    white-space: nowrap;

    @media ($medium-screen) { 
        padding: 0px 20px;
        width: calc(100% - 40px);
    }
    @media ($small-screen) {
        display: none;
    }

    &--logo {
        position: relative;
        width: 100%;
        text-align: center;

        @media ($medium-screen) { 
        }
        @media ($small-screen) {
            display: none;
        }

        &--main {
            display: inline-block;
            cursor: pointer;
            font-family: 'Orator';
            font-weight: bold;
            font-size: 30px;
            letter-spacing: -1px;

            @media ($medium-screen) { 
                font-size: 22px;
            }
            @media ($small-screen) {
            }
        }
    }

    &--menus {

        &--tab {
            margin: 0;
            padding: 0;
            font-size: 16px;
            font-weight: 600;
            background: none;
            color: #ddd;
            transition: all 0.3s;

            &:hover {
                color: white;
            }

            @media ($medium-screen) { 
                font-size: 14px;
            }
            @media ($small-screen) {
            }
        }

        &.left {
            >button {
                &:not(:first-child) {
                    margin-left: 40px;

                    @media ($medium-screen) { 
                        margin-left: 20px;
                    }
                    @media ($small-screen) {
                    }
                }
            }
        }

        &.right {
            >button {
                &:not(:last-child) {
                    margin-right: 40px;

                    @media ($medium-screen) { 
                        margin-right: 20px;
                    }
                    @media ($small-screen) {
                    }
                }
            }
        }
    }

    &--mobile-menu {
        position: fixed;
        z-index: 10;
        display: none;
        width: 100%;
        @media ($medium-screen) { 
        }
        @media ($small-screen) {
            display: block;
        }

        &.show-list {
            height: 100%;
            background: rgb(31 31 31 / 95%);
        }

        &--header {
            color: white;
            display: flex;
            align-items: center;
            padding: 0px 20px;
            height: 60px;
        }

        &--logo {
            cursor: pointer;
            font-family: 'Orator';
            font-weight: bold;
            font-size: 20px;
            letter-spacing: -1px;
            width: 100%;
        }

        &--trigger-button {
            font-size: 20px;
            background: transparent;
            color: #ddd;
            padding: 0;
            margin: 0;

            >svg {
                transform: translateY(1px);
            }
        }

        &--list {
            border-top: 1px solid #aaa;
            padding-top: 10px;

            &--main {
                animation: layout-slide-down 300ms;
                display: flex;
                flex-direction: column;
            }

            &--tab {
                font-size: 20px;
                font-weight: 600;
                text-align: left;
                padding: 10px 20px;
                background: transparent;
                color: #ddd;
                display: flex;
                align-items: center;

                &:hover {
                    color: white;
                }

                >svg {
                    font-size: 24px;
                    margin-right: 20px;
                }
            }
            &--footer {
                .footer {
                    &--main {
                        display: flex;
                        flex-direction: column;
                        font-weight: 600;
                        padding-top: 10px;
                    }
                    &--links {
                        gap: 20px;
                    }
                    &--disclaimer-button {
                        font-size: 14px;
                        font-weight: 500;
                    }
                    &--copyright {
                        text-align: center;
                        line-height: 20px;
                        padding-bottom: 10px;
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    color: #ccc;
    z-index: 100;
    font-size: 13px;
    line-height: 30px;

    @media ($medium-screen) { 
        font-size: 12px;
    }
    @media ($small-screen) {
    }

    &--links {
        display: flex;
        align-items: center;
        gap: 10px;

        @media ($medium-screen) { 
        }
        @media ($small-screen) {
            justify-content: center;
        }
    }

    &--main {
        position: relative;
        padding: 0px 20px;
        margin: 0 auto;
        display: flex;
        white-space: nowrap;
        background: #00000040;
    }

    &--disclaimer-button {
        font-size: 13px;
        font-weight: 500;
        padding: 0;
        background: none;
        color: #ccc;
        transition: all 0.3s;

        &:hover {
            color: white;
        }
    }

    &--copyright {
        width: 100%;
        text-align: right;
        font-weight: 500;

        >br {
            display: none;

            @media ($medium-screen) { 
            }
            @media ($small-screen) {
                display: initial;
            }
        }
    }
}