@import './variables';

@font-face {
    font-family: 'Orator';
    font-style: normal;
	font-weight: normal;
    src: url('Orator-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Pacaembu';
    font-style: normal;
	font-weight: 300;
    src: url('Pacaembu-Thin.otf') format('truetype');
}

@font-face {
    font-family: 'Pacaembu';
    font-style: normal;
	font-weight: 400;
    src: url('Pacaembu-Light.otf') format('truetype');
}

@font-face {
    font-family: 'Pacaembu';
    font-style: normal;
	font-weight: 500;
    src: url('Pacaembu-Medium.otf') format('truetype');
}

@font-face {
    font-family: 'Pacaembu';
    font-style: normal;
	font-weight: 600;
    src: url('Pacaembu-Regular.otf') format('truetype');
}

@font-face {
    font-family: 'Pacaembu';
    font-style: normal;
	font-weight: 700;
    src: url('Pacaembu-Bold.otf') format('truetype');
}

@font-face {
    font-family: 'Pacaembu';
    font-style: normal;
	font-weight: 800;
    src: url('Pacaembu-Black.otf') format('truetype');
}

@font-face {
    font-family: 'Pacaembu';
    font-style: normal;
	font-weight: 900;
    src: url('Pacaembu-Ultra.otf') format('truetype');
}

html {
	position: relative;
	width: 100%;
	height: 100%;
}

body {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
	overflow: hidden;
}

body, div, p, span, video, button, select {
	font-family: 'Pacaembu', sans-serif;
	image-rendering: initial;	
	-webkit-font-smoothing: antialiased;
	-webkit-overflow-scrolling: touch;
	-webkit-text-size-adjust: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

a {
    text-decoration: none;
}

input, textarea {
    cursor: default;
    -webkit-font-smoothing: antialiased;
	font-family: 'Pacaembu', sans-serif;
	font-family: '', sans-serif;
    image-rendering: initial;
    border: 1px solid;

	&:disabled {
		cursor: not-allowed !important;
	}
}

input, textarea, div, button, select {
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	outline: 0px !important;
	-webkit-font-smoothing: antialiased;
	border-radius: 0px;

	&:disabled {
		cursor: not-allowed;
	}
}

button {
    cursor: pointer;
    border: none;
	-webkit-text-size-adjust: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&:disabled {
		cursor: not-allowed;
	}
}

::-webkit-scrollbar {
	width: 7px;
	height: 7px;
	background-color: transparent
}

::-webkit-scrollbar-thumb {
	border-radius: 0px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #212121;
}

::-webkit-scrollbar-corner {
	background: transparent;
}

.hidden {
    display: none !important;
}

.clearfix:after {
	content: " ";
	visibility: hidden;
	display: block;
	height: 0;
	clear: both;
}

.horizontal-centered {
	left: 50%;
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
}
 
 .vertical-centered {
	top: 50%;
	-webkit-transform: translate(0%, -50%);
	-ms-transform: translate(0%, -50%);
	transform: translate(0%, -50%);
}
 
.centered {
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);	
}

.ReactModal__Content {
	position: fixed;
    width: 100%;
    height: 100%;
	background: #00000080;
	z-index: 100;
	top: 0px;
	left: 0px;
}